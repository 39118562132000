<template>
  <div class="page">
    <div id="app">
      <div style="margin: 0 auto">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default{
// methods:{},
// mounted() {
//     // window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
//     window.addEventListener('beforeunload',  function (event){
//       navigator.sendBeacon('http://exposomex.cn/exposome/FuncExit');
//     })
//     },
}

</script>

<style lang="scss" >
.page {
  background-color: #fff;
  
}
#app {
  font-family: 'Times New Roman', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0 auto;
  font-size: 18px;
  max-width:100%;
  height: 100vh;
  background-color: #ffffff;
  overflow: auto;
  justify-content: space-between;
  
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.header {
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  width: 950px;
  margin-bottom: 10px;
}

.footer {
  padding-top: 10px;
  border-top: 5px solid #94070a;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  width: 950px;
  margin: 15px auto;
}
.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex1 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.cursor {
  cursor: pointer;
}
p {
  word-wrap: break-word;
  word-break: break-all;
}
div {
  word-wrap: break-word;
  word-break: break-all;
}
.page-img {
  margin-right: 5px;
  width: 12px;
  display: inline-block;
  position: relative;
  top: -2px;
}
</style>
