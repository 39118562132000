import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect:'/home',
    component: () =>
      import("../views/mainPage.vue"),
    children: [
      {
        path: "home",
        name: "home",
        component: () =>
          import("../views/home.vue"),
      },
      {
        path: "community",
        name: "community",
        component: () =>
          import( "../views/community.vue"),
      },
      {
        path: "/community_database",
        name: "community_database",
        component: () =>
          import( "../views/community_database.vue"),
      },
      {
        path: "/community_expometa",
        name: "community_expometa",
        component: () =>
          import( "../views/community_expometa.vue"),
      },
      {
        path: "/database100",
        name: "database100",
        component: () =>
          import( "../views/Database100.vue"),
      },
      {
        path: "/database101",
        name: "database101",
        component: () =>
          import( "../views/Database101.vue"),
      },
      {
        path: "/demodata100",
        name: "demodata100",
        component: () =>
          import( "../views/DemoData100.vue"),
      },
      {
        path: "/demodata101",
        name: "demodata101",
        component: () =>
          import( "../views/DemoData101.vue"),
      },
      {
        path: "login",
        name: "login",
        component: () =>
          import( "../views/login.vue"),
      },
      {
        path: "register",
        name: "register",
        component: () =>
          import( "../views/register.vue"),
      },
      {
        path: "sendDetails",
        name: "sendDetails",
        component: () =>
          import( "../views/sendDetails.vue"),
      },
      {
        path: "resetpassword",
        name: "reset",
        component: () =>
          import( "../views/resetpassword.vue"),
      },
    
     
      {
        path: "toturial",
        name: "toturial",
        component: () =>
          import( "../views/toturial.vue"),
      },
      {
        path: "download",
        name: "download",
        component: () =>
          import( "../views/download.vue"),
      },
      {
        path: "ex",
        name: "ex",
        component: () =>
          import( "../views/ex.vue"),
      },{
        path: "update",
        name: "update",
        component: () =>
          import( "../views/update.vue"),
      },{
        path: "publication",
        name: "publication",
        component: () =>
          import( "../views/publication.vue"),
      },{
        path: "contact",
        name: "contact",
        component: () =>
          import( "../views/contact.vue"),
      },{
        path: "about",
        name: "about",
        component: () =>
          import( "../views/about.vue"),
      },
      {
        path: "binwang",
        name: "binwang",
        component: () =>
          import( "../views/binwang.vue"),
      },
      {
        path: "lancx",
        name: "lancx",
        component: () =>
          import( "../views/lancx.vue"),
      },
      {
        path: "renmy",
        name: "renmy",
        component: () =>
          import( "../views/renmy.vue"),
      },
      {
        path: "gaon",
        name: "gaon",
        component: () =>
          import( "../views/gaon.vue"),
      },
      {
        path: "fengyq",
        name: "fengyq",
        component: () =>
          import( "../views/fengyq.vue"),
      },
      {
        path: "linwn",
        name: "linwn",
        component: () =>
          import( "../views/linwn.vue"),
      },
      {
        path: "zhanggh",
        name: "zhanggh",
        component: () =>
          import( "../views/zhanggh.vue"),
      },
      {
        path: "mlfang",
        name: "mlfang",
        component: () =>
          import( "../views/mlfang.vue"),
      },{
        path: "description",
        name: "description",
        component: () =>
          import( "../views/description.vue"),
      },{
        path: "visualization",
        name: "visualization",
        component: () =>
          import( "../views/visualization.vue"),
      },{
        path: "database",
        name: "database",
        component: () =>
          import( "../views/database.vue"),
      },{
        path: "cross-sectional",
        name: "cross-sectional",
        component: () =>
          import( "../views/cross-sectional.vue"),
      },
      {
        path: "tidydata",
        name: "tidydata",
        component: () =>
          import( "../views/tidydata.vue"),
      },{
        path: "multi-omics",
        name: "multi-omics",
        component: () =>
          import( "../views/multi-omics.vue"),
      },{
        path: "non-target",
        name: "non-target",
        component: () =>
          import( "../views/non-target.vue"),
      },{
        path: "panel",
        name: "panel",
        component: () =>
          import( "../views/panel.vue"),
      },{
        path: "survival",
        name: "survival",
        component: () =>
          import( "../views/survival.vue"),
      },{
        path: "mediation-effect",
        name: "mediation-effect",
        component: () =>
          import( "../views/mediation-effect.vue"),
      },{
        path: "mixture-effect",
        name: "mixture-effect",
        component: () =>
          import( "../views/mixture-effect.vue"),
      },{
        path: "statistical",
        name: "statistical",
        component: () =>
          import( "../views/statistical.vue"),
      },{
        path: "biological",
        name: "biological",
        component: () =>
          import( "../views/biological.vue"),
      },{
        path: "meta-analysis",
        name: "meta-analysis",
        component: () =>
          import( "../views/meta-analysis.vue"),
      },{
        path: "hexppredict",
        name: "hexppredict",
        component: () =>
          import( "../views/hexppredict.vue"),
      },{
        path: "ivfprediction",
        name: "ivfprediction",
        component: () =>
          import( "../views/ivfprediction.vue"),
      },
    ],
  },
  {
        path: "/estat",
        name: "estat",
        component: () =>
          import( "../views/exstat.vue"),
  },
  {
    path: "/statcros",
    name: "statcros",
    component: () =>
      import( "../views/Expoverse.vue"),
  }, 
  {
    path: "/statsurv",
    name: "statsurv",
    component: () =>
      import( "../views/ExpoSurvival.vue"),
  }, 
  {
    path: "/statmedt",
    name: "statmedt",
    component: () =>
      import( "../views/Expomediation.vue"),
  },
  {
    path: "/statmo",
    name: "statmo",
    component: () =>
      import( "../views/Expomultiomics.vue"),
  },
  {
    path: "/emeta",
    name: "emeta",
    component: () =>
      import( "../views/ExpoMeta.vue"),
  },
  {
    path: "/statdesc",
    name: "statdesc",
    component: () =>
      import( "../views/ExpoStatistics.vue"),
  },
  {
    path: "/eviz",
    name: "eviz",
    component: () =>
      import( "../views/Expoviz.vue"),
  },
  {
    path: "/ebio",
    name: "ebio",
    component: () =>
      import( "../views/Expobiolink.vue"),
  },
  {
    path: "/stathep",
    name: "stathep",
    component: () =>
      import( "../views/ExpoHExpPredict.vue"),
  },
  {
    path: "/IVFPred",
    name: "IVFPred",
    component: () =>
      import( "../views/IVFPred.vue"),
  },
  {
    path: "/ems",
    name: "ems",
    component: () =>
      import( "../views/Exponta.vue"),
  },
  {
    path: "/statip",
    name: "statip",
    component: () =>
      import( "../views/Expostatlink.vue"),
  },
  {
    path: "/statpanel",
    name: "statpanel",
    component: () =>
      import( "../views/Expopanel.vue"),
  },
  {
    path: "/statmix",
    name: "statmix",
    component: () =>
      import( "../views/Expomixeffect.vue"),
  },
  {
    path: "/edb",
    name: "edb",
    component: () =>
      import( "../views/ExpoDb.vue"),
  },
  {
    path: "/dataformat_medt",
    name: "dataformat_medt",
    component: () =>
      import( "../views/dataformat_medt.vue"),
  },
  {
    path: "/stattidy",
    name: "stattidy",
    component: () =>
      import( "../views/ExpoTidydata.vue"),
  },
];

const router = new VueRouter({
  mode:'hash',
  routes,
});

export default router;
