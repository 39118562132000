//引用axios,设置请求、相应拦截器
import axios from 'axios'
import Vue from 'vue'
import router from './router'


const http = axios.create({
    // baseURL: 'https://exposomex.com/',
    baseURL: 'http://www.exposomex.cn/',
    // baseURL: 'http://127.0.0.1:5000/',
    // baseURL: 'http://82.157.29.119/', 
    
})

axios.defaults.withCredentials = true;
http.interceptors.response.use(res => {
    if (res.data.code == 401) {
        console.log(404)
    }
    else {
        return res
    }
    
})

export default http
